.home-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: $xxl * 5;
  padding-top: $xxl * 4;
  #bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  h1 {
    text-align: center;
    margin-top: $m;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: $primary;
    opacity: 0.15;
    z-index: 1;
  }

  .above-video {
    z-index: 2;
  }

  .wrapper {
    min-width: 500px;
    max-width: 500px;
    margin: 0 auto;
    padding-top: $xxl;
  }

  .logo {
    width: 250px;
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 40px;
    padding: $m;
    font-size: $large;
    color: $txtRevert;
    border-radius: 5px;
    outline: 0px;
    border: 0px;
  }

  button {
    margin-right: $xl;
    &.active {
      background-color: $secondary;
      border-color: $secondary;
      &:hover {
        border-color: $secondary;
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }

  .form {
    margin-top: $m;
    button {
      margin-top: $xxl;
      margin-right: 0;
    }
  }

  .loading-icon {
    justify-content: center;
  }
}

.plan-desc {
  margin-top: $xl;
  display: flex;
  flex-direction: row;
  img {
    height: 180px;
    object-fit: cover;
    margin-right: $xl;
  }

  .plan-info {
    p {
      flex: 1;
      display: flex;
      text-align: right;
      font-weight: 600;
    }
    span {
      text-align: left;
      display: inline-block;
      min-width: 100px;
      flex: 1;
      font-weight: 600;
      color: $primary;
    }
  }
}

.status-section {
  text-align: center;
}
img.success-img {
  max-width: 300px;
  object-fit: cover;
}

.intro-mascows {
  display: flex;
  flex-direction: row;
  margin-bottom: $xl;
  justify-content: space-evenly;
  img {
    height: 150px;
    object-fit: cover;
  }
}

.note {
  text-align: center;
}

#payment-element label {
  color: $txt !important;
}

.mobile-bg {
  background-image: url("/assets/bg.png");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 600px) {
  .home-page {
    padding-left: $l;
    padding-right: $l;
    .wrapper {
      min-width: auto;
      max-width: auto;
    }
    .switch-button {
      button {
        margin-bottom: $m;
      }
    }
  }
}
